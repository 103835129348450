import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../API";
import { toast } from "react-toastify";
import { signPicture } from "./listing";

export const fetch_propartyData = createAsyncThunk(
  "/propartyDetails",
  async ({ currency, id }) => {
    try {
      let res = await axiosInstance.get(
        `${id}/?currency=${currency ? currency : "AED"}`
      );
      if (!res?.data) return res?.data;
      if (!res.data.pictures) return res.data;
      res.data.thumbnails = [];
      res.data.fullResolution = [];
      for (const picture of res.data.pictures) {
        res.data.thumbnails.push(signPicture(picture, true));
        res.data.fullResolution.push(signPicture(picture, false));
      }
      return res.data;
    } catch (error) {
      console?.log(error);
    }
  }
);

export const post_select_date = createAsyncThunk(
  "/propartyDetails",
  async ({ currency, id }) => {
    try {
      let res = await axiosInstance.get(
        `${id}/?currency=${currency ? currency : "AED"}`
      );
      return res?.data;
    } catch (error) {
      console?.log(error);
    }
  }
);
// export const fetch_calendarPrice = createAsyncThunk(
//   "/fetch_calendarPrice",
//   async ({ currency, id, checkIn, checkOut }) => {
//     try {
//       let res = await axiosInstance.get(
//         `${id}/calendar/?startDate=${checkIn}&endDate=${checkOut}&currency=${
//           currency ? currency : "AED"
//         }`
//       );
//       return res?.data;
//     } catch (error) {
//       console?.log(error);
//     }
//   }
// );
// export const fetch_price_Date = createAsyncThunk(
//   "/fetch_calendar",
//   async ({ currency, id, checkIn, checkOut, guests }) => {
//     try {
//       let res = await axiosInstance.get(
//         `${id}/price/?checkIn=${checkIn}&checkOut=${checkOut}&currency=${
//           currency ? currency : "AED"
//         }&guests=${guests}`
//       );
//       return res?.data;
//     } catch (error) {
//       console?.log(error);
//     }
//   }
// );
// export const fetch_propartyPrice_cal = createAsyncThunk(
//   "/fetch_calendarPrice/booking",
//   async ({ currency, id, startDate, endDate, guests }) => {
//     try {
//       let res = await axiosInstance.get(
//         `${id}/calendar/?startDate=${startDate}&endDate=${endDate}&currency=${
//           currency ? currency : "AED"
//         }`
//       );
//       return res?.data;
//     } catch (error) {
//       console?.log(error);
//     }
//   }
// );
// export const fetch_propartyPrice_cal_static = createAsyncThunk(
//   "/fetch_calendarPrice/booking/static",
//   async ({ currency, id, startDate, endDate, guests }) => {
//     try {
//       let res = await axiosInstance.get(
//         `${id}/calendar/?startDate=${startDate}&endDate=${endDate}&currency=${
//           currency ? currency : "AED"
//         }`
//       );
//       return res?.data;
//     } catch (error) {
//       console?.log(error);
//     }
//   }
// );
/** ==== STRIPE ===== */
export const makeStripPayment = createAsyncThunk(
  "payment/stripe/",
  async (obj) => {
    try {
      let res = await axiosInstance.post("payment/stripe/", obj);
      return res?.data;
    } catch (error) {
      console?.log(error);
    }
  }
);

export const confirmReservation = createAsyncThunk(
  "listings/reservation",
  async (obj) => {
    try {
      let res = await axiosInstance.post("reservation", obj);
      return res?.data;
    } catch (error) {
      console?.log(error);
    }
  }
);
/** ==== STRIPE ===== */

/** +++++ RAZOR PAY +++++ */
export const makeRazorPayment = createAsyncThunk(
  "payment/razorpay/create",
  async (obj) => {
    try {
      let res = await axiosInstance.post("payment/razorpay/create", obj);
      return res?.data;
    } catch (error) {
      console?.log(error);
    }
  }
);

export const confirmReservationRazorPay = createAsyncThunk(
  "payment/razorpay/confirm",
  async (obj) => {
    try {
      let res = await axiosInstance.post("payment/razorpay/confirm", obj);
      return res?.data;
    } catch (error) {
      console?.log(error);
    }
  }
);
/** +++++ RAZOR PAY +++++ */

const initialState = {
  proparty_data: null,
  proparty_status: "idle",
  price_wise_data: null,
  price_wise_status: "idle",
  cal_wise_data: null,
  cal_wise_status: "idle",
  cal_stripe: null,
  cal_stripe_status: "idle",
  cal_Reservation: null,
  cal_Reservation_status: "idle",
  cal_razor_pay: null,
  cal_razor_pay_status: "idle",
  cal_Reservation_Razor: null,
  cal_Reservation_Razor_status: "idle",
  cal_wise_data_static: null,
  cal_wise_static_status: "idle",
};
export const proparty = createSlice({
  name: "propartyDetails",
  initialState,
  reducers: {},
  extraReducers: {
    [fetch_propartyData.pending]: (state) => {
      state.proparty_status = "loading";
      state.proparty_data = null;
    },
    [fetch_propartyData.fulfilled]: (state, { payload }) => {
      state.proparty_status = "idle";
      state.proparty_data = payload;
    },
    [fetch_propartyData.rejected]: (state) => {
      state.proparty_status = "idle";
    },
    //fetch_price_Date wise price
    // [fetch_price_Date.pending]: (state) => {
    //   state.price_wise_status = "loading";
    // },
    // [fetch_price_Date.fulfilled]: (state, { payload }) => {
    //   state.price_wise_status = "idle";
    //   state.price_wise_data = payload?.result;
    // },
    // [fetch_price_Date.rejected]: (state) => {
    //   state.price_wise_status = "idle";
    // },
    //fetch_cal_data
    // [fetch_propartyPrice_cal.pending]: (state) => {
    //   state.cal_wise_status = "loading";
    // },
    // [fetch_propartyPrice_cal.fulfilled]: (state, { payload }) => {
    //   state.cal_wise_status = "idle";
    //   state.cal_wise_data = payload;
    // },
    // [fetch_propartyPrice_cal.rejected]: (state) => {
    //   state.cal_wise_status = "idle";
    // },
    //fetch_cal_data static
    // [fetch_propartyPrice_cal_static.pending]: (state) => {
    //   state.cal_wise_static_status = "loading";
    //   state.cal_wise_data_static = null;
    // },
    // [fetch_propartyPrice_cal_static.fulfilled]: (state, { payload }) => {
    //   state.cal_wise_static_status = "idle";
    //   state.cal_wise_data_static = payload;
    // },
    // [fetch_propartyPrice_cal_static.rejected]: (state) => {
    //   state.cal_wise_static_status = "idle";
    // },
    //fetch_stripe_data
    [makeStripPayment.pending]: (state) => {
      state.cal_stripe_status = "loading";
      state.cal_stripe = null;
    },
    [makeStripPayment.fulfilled]: (state, { payload }) => {
      state.cal_stripe_status = "idle";
      state.cal_stripe = payload?.results;
      localStorage.setItem("stripeClient", JSON.stringify(payload?.results));
    },
    [makeStripPayment.rejected]: (state) => {
      state.cal_stripe_status = "idle";
    },

    //fetch_reservation_data
    [confirmReservation.pending]: (state) => {
      state.cal_Reservation_status = "loading";
      state.cal_Reservation = null;
    },
    [confirmReservation.fulfilled]: (state, { payload }) => {
      state.cal_Reservation_status = "idle";
      state.cal_Reservation = payload;
      if (payload?.success) {
        // toast(payload?.message, { type: "success" });
        localStorage.setItem("userDetails", JSON.stringify(payload));
        localStorage.removeItem("stripeClient");
        localStorage.removeItem("userDetils");
      } else {
        toast(payload?.message, { type: "error" });
        localStorage.removeItem("stripeClient");
        localStorage.removeItem("userDetils");
      }
    },
    [confirmReservation.rejected]: (state) => {
      state.cal_Reservation_status = "idle";
    },

    /// RAZOR PAY

    [makeRazorPayment.pending]: (state) => {
      state.cal_razor_pay_status = "loading";
      state.cal_razor_pay = null;
    },
    [makeRazorPayment.fulfilled]: (state, { payload }) => {
      state.cal_razor_pay_status = "idle";
      state.cal_razor_pay = payload?.results;
      // localStorage.setItem("razorPay", JSON.stringify(payload?.results));
    },
    [makeRazorPayment.rejected]: (state) => {
      state.cal_razor_pay_status = "idle";
    },

    [confirmReservationRazorPay.pending]: (state) => {
      state.cal_Reservation_Razor_status = "loading";
      state.cal_Reservation_Razor = null;
    },
    [confirmReservationRazorPay.fulfilled]: (state, { payload }) => {
      state.cal_Reservation_Razor_status = "idle";
      state.cal_Reservation_Razor = payload;
      if (payload?.success) {
        window.location.href = `/success-payment?currency=INR`;
        // toast(payload?.message, { type: "success" });
        localStorage.setItem("userDetails", JSON.stringify(payload));
      } else {
        toast(payload?.message, { type: "error" });
      }
    },
    [confirmReservationRazorPay.rejected]: (state) => {
      state.cal_Reservation_Razor_status = "idle";
    },
  },
});
