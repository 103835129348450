import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../API";
import ImgixClient from "@imgix/js-core";

const client = new ImgixClient({
  domain: "silkhaus.imgix.net",
  secureURLToken: "K2tHEYju9zWqVWv5",
});

export const signPicture = (picture, thumbnail = false) =>
  client.buildURL(picture, {
    auto: "format,compress",
    w: thumbnail ? 960 : undefined,
    h: thumbnail ? 430 : undefined,
    fit: thumbnail ? "crop" : undefined,
  });

export const fetch_searchListing = createAsyncThunk(
  "/listing",
  async ({ currency, checkIn, checkOut, bedrooms, city, state, page, price, excludePropertyDetails }) => {
    const check = checkOut ? `&checkIn=${checkIn}&checkOut=${checkOut}` : "";
    const no_of_bedrooms = bedrooms ? `&bedrooms=${bedrooms}` : "";
    const ccity = city ? `&city=${city}` : "";
    const states = state ? `&neighborhood=${state}` : "";
    const excludeFields = excludePropertyDetails ? `&excludePropertyDetails=${excludePropertyDetails}` : "";
    const size = `&size=15`;
    const end = check + no_of_bedrooms + ccity + states + size + excludeFields;
    try {
      let res = await axiosInstance.get(
        `?currency=${currency ? currency : "AED"}${end}&page=${
          page ? page : 1
        }&price=${price}`
      );
      if (!res?.data?.results) return res?.data;

      for (const listing of res.data.results) {
        if (!listing.pictures) continue;
        listing.thumbnails = [];
        for (const picture of listing.pictures) {
          listing.thumbnails.push(signPicture(picture, true));
        }
      }
      return res.data;
    } catch (error) {
      console?.log(error);
    }
  }
);
export const fetch_searchWithOutFilter = createAsyncThunk(
  "/listingWithoutFilter",
  async ({ currency, page }) => {
    try {
      let res = await axiosInstance.get(
        `?currency=${currency ? currency : "AED"}&page=${page ? page : 1}`
      );
      return res?.data;
    } catch (error) {
      console?.log(error);
    }
  }
);
export const fetch_featuredListing = createAsyncThunk(
  "/fetch_featuredListing",
  async ({ currency, city }) => {
    // const ccity = city ? `&city=${city}` : "";
    // const end = ccity;
    try {
      let res = await axiosInstance.get(
        `featured/?city=${city ? city : "Dubai"}&currency=${
          currency ? currency : "AED"
        }`
      );
      if (!res?.data?.results) return res?.data;

      for (const listing of res.data.results) {
        if (!listing.pictures) continue;
        listing.thumbnails = [];
        listing.fullResolution = [];
        for (const picture of listing.pictures) {
          listing.thumbnails.push(signPicture(picture, true));
          listing.fullResolution.push(signPicture(picture, false));
        }
      }
      return res.data;
    } catch (error) {
      console?.log(error);
    }
  }
);

export const post_Properties_Maps_Search = createAsyncThunk(
  "/post_Properties_Maps_Search",
  async ({ maxLat, minLat, maxLon, minLon, currency, price }) => {
    try {
      const res = await axiosInstance.get(
        `?maxLat=${maxLat}&maxLng=${maxLon}&minLat=${minLat}&minLng=${minLon}&page=1&currency=${
          currency ? currency : "AED"
        }&price=${price}`
      );
      const data = {
        count: res.data.count,
        results: res.data.results,
      };
      let next = res.data.next;
      while (next) {
        const res = await axiosInstance.get(next);
        if (res?.data.results?.length > 0)
          data.results.push(...res.data.results);
        next = res?.data?.next;
      }
      data.results = data.results.sort().map((listing) => {
        if (!listing.pictures) return listing;
        listing.thumbnails = [];
        for (const picture of listing.pictures) {
          listing.thumbnails.push(signPicture(picture, true));
        }
        return listing;
      });
      return data;
    } catch (error) {
      console?.log(error);
    }
  }
);

export const fetch_similarListing = createAsyncThunk(
  "/post_Properties_Map",
  async ({ id, currency, excludePropertyDetails }) => {
    const excludeFields = excludePropertyDetails ? `&excludePropertyDetails=${excludePropertyDetails}` : "";
    try {
      let res = await axiosInstance.get(`${id}/similar?currency=${currency}${excludeFields}`);
      if (!res?.data?.results) return res?.data;

      for (const listing of res.data.results) {
        if (!listing.pictures) continue;
        listing.thumbnails = [];
        for (const picture of listing.pictures) {
          listing.thumbnails.push(signPicture(picture, true));
        }
      }
      return res.data;
    } catch (error) {
      console?.log(error);
    }
  }
);
const initialState = {
  searchlisting_status: "idle",
  searchlisting_data: null,
  navigate: false,
  featuredListing_status: "idle",
  featuredListing_data: null,
  searchWithoutFilter_data: null,
  searchWithoutFilter_status: "idle",
  keys: null,
  Properties_Maps_Search_status: "idle",
  Properties_Maps_Search: null,
  similarListing_status: "idle",
  similarListing_data: null,
};

export const listing = createSlice({
  name: "listing",
  initialState,
  reducers: {
    reset: (state) => {
      state.navigate = false;
    },
    searchKeys: (state, { payload }) => {
      state.keys = payload;
    },
  },
  extraReducers: {
    //all_listing
    [fetch_searchListing.pending]: (state) => {
      state.searchlisting_status = "loading";
      // state.searchlisting_data = null;
    },
    [fetch_searchListing.fulfilled]: (state, { payload }) => {
      state.searchlisting_status = "idle";
      state.searchlisting_data = payload;
      state.navigate = true;
      state.searchWithoutFilter_data = null;
      state.Properties_Maps_Search = null;
    },
    [fetch_searchListing.rejected]: (state) => {
      state.searchlisting_status = "idle";
    },
    //fetch_featuredListing
    [fetch_featuredListing.pending]: (state) => {
      state.featuredListing_status = "loading";
    },
    [fetch_featuredListing.fulfilled]: (state, { payload }) => {
      state.featuredListing_status = "idle";
      state.featuredListing_data = payload?.results;
    },
    [fetch_featuredListing.rejected]: (state) => {
      state.featuredListing_status = "idle";
    },
    //fetch_searchWithoutFilter
    [fetch_searchWithOutFilter.pending]: (state) => {
      state.searchWithoutFilter_status = "loading";
    },
    [fetch_searchWithOutFilter.fulfilled]: (state, { payload }) => {
      state.searchWithoutFilter_status = "idle";
      state.searchWithoutFilter_data = payload;
      state.searchlisting_data = null;
      state.Properties_Maps_Search = null;
      // state.navigate=true
    },
    [fetch_searchWithOutFilter.rejected]: (state) => {
      state.searchWithoutFilter_status = "idle";
    },

    /** ===Properties_Maps_Search ==== */

    [post_Properties_Maps_Search.pending]: (state) => {
      state.Properties_Maps_Search_status = "loading";
      // state.Properties_Maps_Search = null;
    },
    [post_Properties_Maps_Search.fulfilled]: (state, { payload }) => {
      state.Properties_Maps_Search_status = "idle";
      state.Properties_Maps_Search = payload;
      state.searchlisting_data = null;
      state.searchWithoutFilter_data = null;
    },
    [post_Properties_Maps_Search.rejected]: (state) => {
      state.Properties_Maps_Search_status = "idle";
    },
    /** ===Properties_Maps_Search ==== */
    [fetch_similarListing.pending]: (state) => {
      state.similarListing_status = "loading";
      state.similarListing_data = null;
    },
    [fetch_similarListing.fulfilled]: (state, { payload }) => {
      state.similarListing_status = "idle";
      state.similarListing_data = payload?.results;
    },
    [fetch_similarListing.rejected]: (state) => {
      state.similarListing_status = "idle";
    },
  },
});
export const { reset, searchKeys } = listing.actions;
