/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import "./Header.scss";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import flag1 from "../../../assets/images/flag01.svg";
import flag2 from "../../../assets/images/flag02.png";
import flag3 from "../../../assets/images/flag03.svg";
import menu from "../../../assets/images/menu-icon.svg";
import close from "../../../assets/images/close-menu.svg";
import fb from "../../../assets/images/m-fb.svg";
import linkdin from "../../../assets/images/m-linkdin.svg";
import tweet from "../../../assets/images/m-tweet.svg";
import insta from "../../../assets/images/m-insta.svg";
import { createUseStyles } from "react-jss";

const countryName = [
  {
    name: "AED",
    image: flag1,
  },
  {
    name: "INR",
    image: flag2,
  },
  {
    name: "USD",
    image: flag3,
  },
];

const Header = () => {
  const { pathname, search } = useLocation();
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(false);

  const classes = useStyles({ expanded });

  const handleExpand = () => setExpanded(!expanded);
  const handleClose = () => setExpanded(false);

  const Lquery = localStorage.getItem("query");
  const Lpic = localStorage.getItem("pic");

  const [query, setQuery] = useState(
    Lquery !== null && Lquery !== undefined ? Lquery : "AED"
  );

  const [pic, setPic] = useState(
    Lpic !== null && Lpic !== undefined ? Lpic : flag1
  );
  const SData = search?.split("=")[1];
  const handleChngCurr = (qry, pictr) => {
    setQuery(qry);
    localStorage.setItem("query", qry);
    setPic(pictr);
    localStorage.setItem("pic", pictr);
  };

  useEffect(() => {
    if (SData?.slice(0, 3) !== Lquery) {
      localStorage.setItem(
        "query",
        SData?.slice(0, 3) ? SData?.slice(0, 3) : "AED"
      );
      setQuery(SData?.slice(0, 3) ? SData?.slice(0, 3) : "AED");
      localStorage.setItem(
        "pic",
        SData?.slice(0, 3) === "AED"
          ? flag1
          : SData?.slice(0, 3) === "INR"
          ? flag2
          : SData?.slice(0, 3) === "USD"
          ? flag3
          : flag1
      );
      setPic(
        SData?.slice(0, 3) === "AED"
          ? flag1
          : SData?.slice(0, 3) === "INR"
          ? flag2
          : SData?.slice(0, 3) === "USD"
          ? flag3
          : flag1
      );
    }
  }, []);
  useEffect(() => {
    navigate(`${pathname}?currency=${query !== undefined ? query : "AED"}`);
  }, [query]);

  const ref = useRef(null);

  // useEffect(() => {
  //   /**
  //    * Alert if clicked on outside of element
  //    */
  //   function handleClickOutside(event) {
  //     setExpanded(false);
  //   }
  //   // Bind the event listener
  //   document.addEventListener("mousedown", handleClickOutside);
  //   return () => {
  //     // Unbind the event listener on clean up
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, []);
  if (expanded) {
    document.body.classList.add("width_bg");
  } else {
    document.body.classList.remove("width_bg");
  }
  return (
    <div className={classes.screen}>
      <div className={classes.main}>
        <div className={classes.container}>
          <div style={{ display: "flex", flex: 1 }}>
            <header className="page_header" style={{ flex: 1 }}>
              <div className="openmobMenu">
                <div className="barnd-logo">
                  <Link
                    to={`/?currency=${query !== undefined ? query : "AED"}`}
                    onClick={handleClose}
                  >
                    {" "}
                    <img
                      src={"../../../assets/images/enterprise-logo.svg"}
                      alt="Silkhaus"
                    />
                  </Link>
                  {/*<span style={{color: '#000000', fontSize: 15, paddingLeft: 30}}>For Businesses</span>*/}
                </div>
                <div className="menubar">
                  {expanded ? (
                    <button onClick={handleClose}>
                      <img src={close} alt="close menu" />
                    </button>
                  ) : (
                    <>
                      <button onClick={handleExpand}>
                        <img src={menu} alt="open menu" />
                      </button>
                    </>
                  )}
                </div>
              </div>
              {expanded && (
                <div className="mobmenu-show">
                  <ul className="main-menu">
                    <li>
                      <NavLink
                        onClick={handleClose}
                        to={`our-properties?currency=${query}`}
                        className={`nav-link ${
                          pathname === "/our-properties" && "link-active"
                        }`}
                      >
                        Our Properties
                      </NavLink>
                    </li>
                    {/*<li>*/}
                    {/*  <Link*/}
                    {/*    onClick={handleClose}*/}
                    {/*    to={`HowItWorks?currency=${query}`}*/}
                    {/*    className={`nav-link ${*/}
                    {/*      pathname === "/HowItWorks" && "link-active"*/}
                    {/*    }`}*/}
                    {/*  >*/}
                    {/*    How It Works*/}
                    {/*  </Link>*/}
                    {/*</li>*/}
                    {/*<li>*/}
                    {/*  <Link*/}
                    {/*    onClick={handleClose}*/}
                    {/*    to={`landlord?currency=${query}`}*/}
                    {/*    className={`nav-link ${*/}
                    {/*      pathname === "/landlord" && "link-active"*/}
                    {/*    }`}*/}
                    {/*  >*/}
                    {/*    Property owners*/}
                    {/*  </Link>*/}
                    {/*</li>*/}
                    <li>
                      <Link
                        onClick={handleClose}
                        to={`about-us?currency=${query}`}
                        className={`nav-link ${
                          pathname === "/about-us" && "link-active"
                        }`}
                      >
                        About Us
                      </Link>
                    </li>
                    {/*<li>*/}
                    {/*  <a*/}
                    {/*    class="nav-link"*/}
                    {/*    href="https://silkhausblog.com"*/}
                    {/*    target="_blank"*/}
                    {/*    rel="noreferrer"*/}
                    {/*    aria-label="blog"*/}
                    {/*  >*/}
                    {/*    Blog*/}
                    {/*  </a>*/}
                    {/*</li>*/}
                    <li>
                      <div style={{ position: "relative", zIndex: 1000 }}>
                        <NavDropdown
                          style={{ position: "relative", zIndex: 1000 }}
                          title={
                            <>
                              <img src={pic} height="13" width="20" alt="" />
                            </>
                          }
                          id="collasible-nav-dropdown"
                          // onClick={() => {
                          //   setExpandedNav(true);
                          // }}
                          active={query}
                        >
                          {countryName.map((item, i) => (
                            <NavDropdown.Item
                              eventKey={item.name}
                              key={i}
                              onClick={(e) => {
                                handleChngCurr(item.name, item.image);
                                handleClose();
                              }}
                            >
                              <img src={item.image} alt="" />
                              <span
                                style={{
                                  color: query === item.name && "black",
                                  fontWeight: query === item.name && "800",
                                }}
                              >
                                {item.name}
                              </span>
                            </NavDropdown.Item>
                          ))}
                        </NavDropdown>
                      </div>
                    </li>
                    <li>
                      <div className="menu-social">
                        <h3>Follow us</h3>
                        <ul>
                          <li>
                            <a
                              href={process.env.REACT_APP_FB_LINK}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img src={fb} alt="" />
                            </a>
                          </li>
                          <li>
                            <a
                              href={process.env.REACT_APP_LIN_LINK}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img src={linkdin} alt="" />
                            </a>
                          </li>
                          {/* <li>
                      <a>
                        <img src={youtube} alt="" />
                      </a>
                    </li> */}
                          <li>
                            <a
                              href={process.env.REACT_APP_TW_LINK}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img src={tweet} alt="" />
                            </a>
                          </li>
                          <li>
                            <a
                              href={process.env.REACT_APP_INS_LINK}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img src={insta} alt="" />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </div>
              )}

              <div className=" desktop-menu">
                <Navbar collapseOnSelect expand="lg" variant="dark">
                  <Navbar.Brand>
                    <NavLink
                      to={`/?currency=${query !== undefined ? query : "AED"}`}
                    >
                      <img src={"../../../assets/images/enterprise-logo.svg"} alt="" />
                    </NavLink>
                    {/*<span style={{color: '#000000', fontSize: 15, paddingLeft: 30}}>For Businesses</span>*/}
                  </Navbar.Brand>
                  <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                  <Navbar.Collapse ref={ref} id="responsive-navbar-nav">
                    <Nav className="ms-auto">
                      <NavLink
                        to={`our-properties?currency=${query}`}
                        className={`nav-link ${
                          pathname === "/our-properties" && "link-active"
                        }`}
                      >
                        Our Properties
                      </NavLink>
                      {/*<NavLink*/}
                      {/*  to={`HowItWorks?currency=${query}`}*/}
                      {/*  className={`nav-link ${*/}
                      {/*    pathname === "/HowItWorks" && "link-active"*/}
                      {/*  }`}*/}
                      {/*>*/}
                      {/*  How It Works*/}
                      {/*</NavLink>*/}
                      {/*<NavLink*/}
                      {/*  to={`landlord?currency=${query}`}*/}
                      {/*  className={`nav-link ${*/}
                      {/*    pathname === "/landlord" && "link-active"*/}
                      {/*  }`}*/}
                      {/*>*/}
                      {/*  Property owners*/}
                      {/*</NavLink>*/}
                      <NavLink
                        to={`about-us?currency=${query}`}
                        className={`nav-link ${
                          pathname === "/about-us" && "link-active"
                        }`}
                      >
                        About Us
                      </NavLink>
                      <div id="dropdown-list">
                        <NavDropdown title="Explore" id="basic-nav-dropdown">
                          <NavDropdown.Item href="https://silkhaus.bamboohr.com/careers" target="_blank">Careers</NavDropdown.Item>
                          <NavDropdown.Item href="https://silkhausblog.com" target="_blank">Blog</NavDropdown.Item>
                        </NavDropdown>
                      </div>
                      {/*<a*/}
                      {/*  className="nav-link"*/}
                      {/*  href="https://silkhausblog.com"*/}
                      {/*  target="_blank"*/}
                      {/*  rel="noreferrer"*/}
                      {/*  aria-label="blog"*/}
                      {/*>*/}
                      {/*  Blog*/}
                      {/*</a>*/}
                      <NavDropdown
                        title={
                          <>
                            <img src={pic} height="13" width="20" alt="" />
                          </>
                        }
                        id="collasible-nav-dropdown"
                        // onClick={() => setExpanded(true)}
                        active={query}
                      >
                        {countryName.map((item, i) => (
                          <NavDropdown.Item
                            eventKey={item.name}
                            key={i}
                            onClick={(e) => {
                              handleChngCurr(item.name, item.image);
                            }}
                          >
                            <img src={item.image} alt="" />
                            <span
                              style={{
                                color: query === item.name && "black",
                                fontWeight: query === item.name && "800",
                              }}
                            >
                              {item.name}
                            </span>
                          </NavDropdown.Item>
                        ))}
                      </NavDropdown>
                    </Nav>
                  </Navbar.Collapse>
                </Navbar>
              </div>
            </header>
          </div>
        </div>
      </div>
    </div>
  );
};

const useStyles = createUseStyles({
  screen: {
    position: "fixed",
    left: 0,
    right: 0,
    width: "100vw",
    // overflowX: "hidden",
    backgroundColor: "white",
    zIndex: 999,
  },
  main: {
    width: "100%",
    display: "flex",
    justifyContent: "space-around",
    // backgroundColor: "pink",
  },
  container: {
    maxWidth: "100%",
    // padding: "0px 10px 0 10px",
    //padding: (props) => (props.expanded ? "0px 0px 0 0px" : "0px 10px 0 10px"),
    // backgroundColor: "green",
    flex: 1,
    display: "flex",
    padding: 0,

    "@media (min-width: 1000px)": {
      padding: "0px 50px 0 50px",
    },

    "@media (min-width:1536px)": {
      maxWidth: "1536px",
      padding: "0px 0px 0 0px",
    },
  },
  content: {
    flex: 1,
  },
});

export default Header;
