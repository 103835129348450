import React from "react";
import location1 from "../assets/images/location.svg";
import { createUseStyles } from "react-jss";

const LocationLink = (props) => {
  const { locationName, disabled } = props;
  const classes = useStyles({ disabled });

  return (
    <div className={classes.root}>
      <img src={location1} alt="img" className="me-2" />
      <div className={classes.text}>{locationName}</div>
    </div>
  );
};

const useStyles = createUseStyles({
  root: {
    fontSize: "15px",
    fontWeight: 400,
    color: "white",
    cursor: "pointer",
    marginBottom: 10,
    display: "flex",
    alignItems: "middle",
    marginBottom: 10,
  },
  text: {
    fontSize: "15px",
    fontFamily: 'Plus Jakarta Sans, sans-serif',
    fontWeight: 400,
    cursor: "pointer",
    color: (props) => (!props.disabled ? "white" : "rgba(255,255,255, .4)"),

    "&:hover": {
      fontWeight: (props) => (!props.disabled ? 600 : 400),
    },

    "@media (min-width:1536px)": {
      // maxWidth: "1536px",
    },
  },
});

export default LocationLink;
