import React, { lazy, Suspense } from "react";
import { HelmetProvider } from "react-helmet-async";
import "./App.scss";
import { Navigate, Route, Routes } from "react-router-dom";
import Header from "./ShareModules/Layout/Header/Header";
import { useEffect, useLayoutEffect } from "react";
import { fetch_cities } from "./redux/location";
import { useDispatch, useSelector } from "react-redux";
import { StripePaymentProvider } from "./CommonComponent/StripePaymentContext";
import { useLocation } from "react-router-dom";

import PrivacyPolicy from "./Components/PrivacyPolicy/PrivacyPolicy";
import NotFoundPage from "./Components/NotFound/NotFoundPage";
import { v4 as uuid } from 'uuid';
import axios from "axios";

///

const About = lazy(() => import("./Components/AboutUs/About"));
const HowItWorks = lazy(() => import("./Components/HowItWorks/HowItWorks"));
const OurProperties = lazy(() =>
  import("./Components/OurProperties/OurPropertiesNew")
);
const Landlord = lazy(() => import("./Components/LandlordPage/Landlord"));
const Index = lazy(() => import("./Components/Home/Index"));
const PropertyDetail = lazy(() =>
  import("./Components/PropertyDetail/PropertyDetail")
);
const SuccessPayment = lazy(() => import("./Components/SuccesPayment"));
const TermsConditions = lazy(() =>
  import("./Components/TermsConditions/TermsConditions")
);
const ProgressBar1 = () => {
  return (
    <div className="position-fixed w-100 top-0 start-0 zindex-9999">
      <div
        style={{ width: "{dynamic}-%", height: "3px", background: "#542686" }}
      />
    </div>
  );
};

function App() {
  const dispatch = useDispatch();
  const { cities_data } = useSelector((s) => s?.city);
  const location = useLocation();

  // Analytical Tracking
  useLayoutEffect(() => {
    let body = {
      "integration_id": process.env.REACT_APP_COEUS,
      event: 'pageview',
      url: window.location.href,
      payload: {
        "path_name": window.location.pathname,
        "url": window.location.href
      }
    };

    axios
      .post("https://getcoeus.com/api/v1/events", body)
      .then(function (response) {
        console.log(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [location]);
  
  useEffect(() => {
    dispatch(fetch_cities());
  }, [dispatch]);
  
  useEffect(() => {
    const unique_user_id = uuid();
    if (localStorage.getItem("unique_ga_user") === null) {
      localStorage.setItem("unique_ga_user", (unique_user_id));

    }
  }, []);

  const cityData = cities_data?.find((item) => item?.city === "Dubai");
  if (cityData !== undefined) {
    localStorage.setItem("cityDetails", JSON.stringify(cityData));
  }

  return (
    <HelmetProvider>
      <StripePaymentProvider>
        <Suspense fallback={<ProgressBar1 />}>
          <Header />

          <div className="pagesWrapper">
            <Routes>
              {/**************  Start public Route *********************/}
              <Route path="/" element={<Index />} />
              <Route path="/property-detail/:id" element={<PropertyDetail />} />
              <Route path="/about-us" element={<About />} />
              {/*<Route path="/HowItWorks" element={<HowItWorks />} />*/}
              <Route path="/our-properties" element={<OurProperties />} />
              {/*<Route path="/landlord" element={<Landlord />} />*/}
              {/*<Route path="/success-payment" element={<SuccessPayment />} />*/}
              <Route path="/terms-and-conditions" element={<TermsConditions />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              {/***************  End public Route  **********************/}

              <Route path="/404" element={<NotFoundPage />} />
              <Route path="/*" element={<Navigate to="/404" />} />
            </Routes>
          </div>

          <Routes>
            <Route path="/our-properties" />
            
          </Routes>
        </Suspense>
      </StripePaymentProvider>
    </HelmetProvider>
  );
}

export default App;
