import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../API";

const getSimilarQuery = ({ city, neighborhood, building, type }) => {
  let query = `city=${city}&neighbourhood=${neighborhood}&type=${type}`;
  if (building) {
    query += `&building=${building}`;
  }

  return query;
};

export const fetch_similar = createAsyncThunk(
  "landlords/similar",
  async (props) => {
    try {
      let res = await axiosInstance.get(
        `landlords/similar?${getSimilarQuery(props)}`
      );

      return res?.data;
    } catch (error) {
      console?.log(error);
    }
  }
);

export const fetch_options = createAsyncThunk("landlords/options", async () => {
  try {
    let res = await axiosInstance.get("landlords/options");

    return res?.data;
  } catch (error) {
    console?.log(error);
  }
});

export const fetch_properties = createAsyncThunk(
  "landlords/properties",
  async () => {
    try {
      let res = await axiosInstance.get("landlords/properties");

      return res?.data;
    } catch (error) {
      console?.log(error);
    }
  }
);

const initialState = {
  //   similar
  similar_data: null,
  similar_status: "idle",

  // options
  options_data: null,
  options_status: "idle",

  // properies
  properties_data: null,
  properties_status: "idle",
};

export const landlords = createSlice({
  name: "location",
  initialState,
  reducers: {},
  extraReducers: {
    // similar
    [fetch_similar.pending]: (state) => {
      state.similar_status = "loading";
    },
    [fetch_similar.fulfilled]: (state, { payload }) => {
      state.similar_status = "idle";
      state.similar_data = payload?.results;
    },
    [fetch_similar.rejected]: (state) => {
      state.similar_status = "idle";
    },

    // similar
    [fetch_options.pending]: (state) => {
      state.options_status = "loading";
    },
    [fetch_options.fulfilled]: (state, { payload }) => {
      state.options_status = "idle";
      state.options_data = payload?.results;
    },
    [fetch_options.rejected]: (state) => {
      state.options_status = "idle";
    },

    // properties
    [fetch_properties.pending]: (state) => {
      state.properties_status = "loading";
    },
    [fetch_properties.fulfilled]: (state, { payload }) => {
      state.properties_status = "idle";
      state.properties_data = payload?.results;
    },
    [fetch_properties.rejected]: (state) => {
      state.properties_status = "idle";
    },
  },
});
