import React from "react";
import "./Footer.scss";
import footerLogo from "../../../assets/images/footerLogo.svg";
import { Link } from "react-router-dom";
import location1 from "../../../assets/images/location.svg";
import fb from "../../../assets/images/SocialMedia/facebook.svg";
import linkedin from "../../../assets/images/SocialMedia/linkedin.svg";
import twitter from "../../../assets/images/SocialMedia/twitter.svg";
import insta from "../../../assets/images/SocialMedia/insta.svg";
import whatsapp from "../../../assets/images/SocialMedia/whatsapp.svg";
import { useLocation } from "react-router-dom";
import { createUseStyles } from "react-jss";
import LocationLink from "../../../CommonComponent/LocationLink";

const Footer = () => {
  const classes = useStyles();
  const location = useLocation();

  const goToWp = () => {
    window.open(process.env.REACT_APP_WP_LINK, "_blank");
  };
  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <img src={footerLogo} alt="img" className={classes.logo} />

        <div className={classes.wrapper}>
          <div>
            <div className={classes.title}>
              <div className={classes.title}>Design your stay.</div>

              <div className={classes.linkWrapper}>
                <LocationLink locationName="Abu Dhabi" />
                <LocationLink locationName="Dubai" />
                {/*<LocationLink locationName="Jeddah" />*/}
                {/*<LocationLink locationName="Riyadh" />*/}
                <LocationLink
                  locationName="Other Cities (Coming soon)"
                  disabled
                />
              </div>
            </div>
          </div>

          <div className={classes.colTwo}>
            <div className={classes.title}>Get in touch</div>
            <a href="tel:+971501234567">
              <div className={classes.link}>+971 52 179 3145</div>
            </a>

            <a href="mailto:reservations@silkhaus.co">
              <div className={classes.link}>reservations@silkhaus.co</div>
            </a>

            <div className={classes.link}>
              Level 5, One JLT, Jumeirah Lake Towers, Dubai, UAE
            </div>
          </div>
          <div>
            <div className={classes.title}>Life at Silkhaus</div>
            <a href="https://silkhaus.bamboohr.com/careers" target="_blank" rel="noreferrer">
              <div className={classes.link}>Careers</div>
            </a>
            <div className={classes.socialLinkWrapper}>
              <a
                  href={process.env.REACT_APP_INS_LINK}
                  target="_blank"
                  rel="noreferrer"
              >
                <img
                    src={insta}
                    alt="img"
                    className={classes.socialLinkIconInstagram}
                />
              </a>

              <a
                href={process.env.REACT_APP_LIN_LINK}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={linkedin}
                  alt="img"
                  className={classes.socialLinkIcon}
                />
              </a>
              <a
                href={process.env.REACT_APP_TW_LINK}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={twitter}
                  alt="img"
                  className={classes.socialLinkIcon}
                />
              </a>
              <a
                  href={process.env.REACT_APP_FB_LINK}
                  target="_blank"
                  rel="noreferrer"
              >
                <img
                    src={fb}
                    alt="img"
                    className={classes.socialLinkIconLast}
                />
              </a>
            </div>
          </div>
        </div>
        <div>
          <div className={classes.divider} />

          <div className={classes.legalRoot}>
            <div className={classes.legalWrapper}>
              <Link
                to={`/terms-and-conditions?currency=${
                  localStorage.getItem("query")
                    ? localStorage.getItem("query")
                    : "AED"
                }`}
                className={classes.terms}
              >
                <div className={classes.link}>T&Cs and Cancellation Policy</div>
              </Link>

              <Link
                to={`/privacy-policy?currency=${
                  localStorage.getItem("query")
                    ? localStorage.getItem("query")
                    : "AED"
                }`}
                className={classes.terms}
              >
                <div className={classes.link}>Privacy Policy</div>
              </Link>
            </div>

            <div className={classes.link}>
              © 2022 Silkhaus | All Rights Reserved{" "}
            </div>
          </div>
          <div className={classes.whatsapp}>
            <button className={classes.whatsapp_btn} onClick={goToWp}>
              <img src={whatsapp} alt="img" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const useStyles = createUseStyles({
  root: {
    width: "100%",
    display: "flex",
    justifyContent: "space-around",
    // backgroundColor: "red",
    // height: 400,
    backgroundColor: "#2f3234",
    paddingTop: 50,
    paddingBottom: 30,
  },
  whatsapp_btn: {
    background: "#6fc567",
    boxShadow: "0px 4px 7px rgba(47, 50, 52, 0.2)",
    width: 48,
    height: 48,
    borderRadius: "50%",
    border: "none",
  },
  whatsapp: {
    position: "fixed",
    right: "30px",
    bottom: "50px",
    zIndex: "50",
  },
  container: {
    width: "100%",
    // padding: "0px 40px 0 30px",
    // backgroundColor: "green",
    display: "flex",
    flexDirection: "column",
    padding: "0px 10px 0 10px",

    "@media (min-width:900px)": {
      padding: "0px 50px 0 50px",
    },

    "@media (min-width:1536px)": {
      maxWidth: "1536px",
      padding: "0px 0px 0 0px",
    },
  },

  logo: {
    width: 120,
  },

  wrapper: {
    display: "flex",
    flexDirection: "column",

    justifyContent: "space-between",
    marginTop: 40,
    paddingBottom: 40,

    // backgroundColor: "pink",

    "@media (min-width:950px)": {
      flexDirection: "row",
    },
  },

  border: {
    height: 2,
    width: "100%",
    // backgroundColor: "red",
  },
  socialLinkWrapper: {
    // backgroundColor: "red",
    display: "flex",
    marginTop: 10
  },
  socialLinkIconInstagram: {
    width: 28,
    height: 28,
    cursor: "pointer",
    marginRight: 30
  },
  socialLinkIcon: {
    width: 30,
    height: 30,
    cursor: "pointer",

    marginRight: 30,
  },
  socialLinkIconLast: {
    width: 28,
    height: 28,
    cursor: "pointer",
    marginRight: 0
  },
  title: {
    fontStyle: "normal",
    fontSize: "16px",
    marginBottom: 25,
    fontFamily: "DM Serif Display, sans-serif",
    color: "white",

    // lineHeight: "22px",
  },
  link: {
    fontSize: "14px",
    fontWeight: 400,
    color: "white",
    cursor: "pointer",
    marginBottom: 10,
    transition: "all .2s",

    "&:hover": {
      fontWeight: 600,
    },
  },
  legalRoot: {
    marginTop: 25,
    display: "flex",
    flexDirection: "column",
    // backgroundColor: "red",
    justifyContent: "space-between",
    alignItems: "center",

    "@media (min-width:950px)": {
      flexDirection: "row",
      alignItems: "flex-start",
    },
  },
  legalWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-around",

    "@media (min-width:950px)": {
      flexDirection: "row",
      alignItems: "flex-start",
    },
  },
  linkWrapper: {
    display: "flex",
    flexDirection: "column",
  },
  colTwo: {
    maxWidth: 250,

    marginTop: 30,
    marginBottom: 30,

    "@media (min-width:950px)": {
      marginTop: 0,
      marginBottom: 0,
    },
  },
  divider: {
    width: "100%",
    height: 1,
    backgroundColor: "rgba(255,255,255, .3)",
  },
  terms: {
    marginRight: 0,

    "@media (min-width:950px)": {
      marginRight: 10,
    },
  },
});

export default Footer;
