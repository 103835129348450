import React from "react";
import { Helmet } from "react-helmet-async";
import "./NotFound.scss";
import { createUseStyles } from "react-jss";
import Footer from "../../ShareModules/Layout/Footer/Footer";

const NotFound = () => {
  const classes = useStyles();

  return (
    <div>
      <Helmet>
        <title>Silkhaus - Page Not Found</title>
        <meta
          name="title"
          content="Silkhaus - Page Not Found"
        />;
        <meta
          name="description"
          content="Silkhaus - Page Not Found"
        />;
      </Helmet>
      <div className={classes.main}>
        <div className={classes.container}>
          <div className="not-found">
            <div className="row">
              <div className="col-lg-12 my-10">
                <h4>Sorry, this page doesn't exist.</h4>
                <a href="/"  className={classes.link}>
                  Back to the homepage
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    <Footer />
    </div >
  );
};

const useStyles = createUseStyles({
  main: {
    width: "100%",
    display: "flex",
    justifyContent: "space-around",
    // backgroundColor: "red",
    // height: 400,
    paddingTop: 50,
    paddingBottom: 30,
  },
  container: {
    width: "100%",
    padding: "0px 10px 0 10px",
    // backgroundColor: "green",
    display: "flex",
    flexDirection: "column",
    // justifyContent: "space-between",

    "@media (min-width:900px)": {
      padding: "0px 50px 0 50px",
    },

    "@media (min-width:1536px)": {
      maxWidth: "1536px",
      padding: "0px 0 0 0",
    },
  },

  wrapper: {
    display: "flex",
    flexDirection: "column",

    justifyContent: "space-between",
    marginTop: 40,
    paddingBottom: 40,

    // backgroundColor: "pink",

    "@media (min-width:950px)": {
      flexDirection: "row",
    },
  },
  link: {
    marginLeft: 2,
    color: "#542686",
  },
  textBold: {
    fontWeight: 700,
  },
});

export default NotFound;