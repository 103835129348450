import React from "react";
import { Helmet } from "react-helmet-async";
import "./PrivacyPolicy.scss";
import { createUseStyles } from "react-jss";
import Footer from "../../ShareModules/Layout/Footer/Footer";

const PrivacyPolicy = () => {
  const classes = useStyles();

  const title = "Silkhaus Privacy Policy - Enjoy hassle-free living at Silkhaus"

  return (
    <div>
      <Helmet>
        <title>{title}</title>
        <meta
          name="title"
          content={title}
        />;
        <meta
          name="description"
          content="Silkhaus Privacy Policy - At Silkhaus, transparency is one of our core values. Read More"
        />;

        <link rel="canonical" href="/privacy-policy"/>
        <meta property="og:title" content={title} />
        <meta property="og:type"  content="website"/>
        <meta property="og:url"  content="/privacy-policy"/>
      </Helmet>
      <div className={classes.main}>
        <div className={classes.container}>
          <div className="terms">
            
            <div className="row">
              <div className="col-lg-12 my-10">
                <h4>Privacy Policy</h4>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <h6>Intro</h6>
                <p>
                  At Silkhaus, transparency is one of our core values. We will
                  be transparent about every aspect of our business, including
                  but not limited, to how we collect and use your personal
                  data. The following privacy policy ("Policy") is developed
                  by Silkhaus Vacation Homes LLC ("us" or "we" or "Silkhaus")
                  for you to understand how we collect, communicate, protect,
                  disclose, and make use of personal information in connection
                  with our website ("Site") or other services (collectively
                  "Services" and each individually as "Service"). In the
                  Policy, you will also find details about how to manage your
                  Personal Information and how to contact us for any questions
                  or concerns.
                </p>
                <p>
                  Please read the Policy carefully as it applies to all of our
                  Services. Your use of our Services constitutes consent to
                  the Policy. If you have questions or concerns, you can
                  contact us at
                  <a href="mailto:info@silkhaus.co" style={{ color: "#542686" }} className={classes.link}>
                    info@silkhaus.co
                  </a>
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 mt-4">
                <h6>Information Collection</h6>
                <p>We collect two types of information:</p>
                <ul>
                  <li>
                    Un-identified and non-identifiable information, collected
                    automatically via the usage of the Services ("Non-personal
                    Information"). We are not able to identify the Visitor or
                    User through these Non-personal Information collection.
                    The here mentioned Non-personal Information consists of
                    technical and aggregated information of use, such as
                    Visitors' and Users' navigating activity and
                    'click-stream' actions, website heatmaps and scrolls,
                    non-identifying information regarding devices, operating
                    system, internet browser, screen resolution, language and
                    keyboard settings, internet service provider,
                    referring/exit pages, date/time stamps, etc.
                  </li>
                  <li>
                    Individually identifiable information, by-name information
                    that identifies an individual or may identify an
                    individual with reasonable efforts, or might be of private
                    or sensitive nature ("Personal Information"). This
                    Information consists of contact details (like, email
                    address or phone number), billing details (name, physical
                    billing address, payment method and transaction details),
                    collected from Users with Paid Services, information of
                    browsing or usage session (IP address, Geo-location and/or
                    device unique identifier), details regarding third party
                    accounts connected to these (e.g., email or username of a
                    connected Google or Facebook account), copies of identity
                    documents provided to us (e.g. ID card, driver's license,
                    passport or official company registration documents),
                    correspondences (also the ones made through or uploaded to
                    our Services), and all other Personal Information provided
                    to us by Visitors and/or Users through their voluntary use
                    of the Services. To avoid any doubt, Non-personal
                    Information connected to Personal Information (with the
                    scope, for example, to improve our Services) is considered
                    and treated as Personal Information, until there's any
                    specified connection.
                  </li>
                </ul>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 mt-4">
                <h6>How the information collection is done</h6>
                <p>We consider three sources of information collection:</p>
                <ul>
                  <li>
                    <strong>The use of the Services.</strong> Visiting or
                    using our Services, when you browse the Website, register
                    an Account, enquire or book a property, we are aware of
                    this and will collect and record such uses, sessions and
                    linked information, both independently and with
                    third-party services, including "cookies" and other
                    tracking implementations.
                  </li>
                  <li>
                    <strong>Information voluntarily provided by you.</strong>{" "}
                    For example, when you register to our Services; when you
                    sign in via third party services (e.g., Facebook or
                    Google); when you make inquiries or bookings; when you
                    disclose uploading Personal Information to use our
                    Services; when you contact us directly through any channel
                    (e.g., telephone, email).
                  </li>
                  <li>
                    <strong>Third party sources information</strong> as
                    described in Section 11 below.
                  </li>
                </ul>
                <p>
                  Some provided information is collected and processed by
                  third parties on our behalf. For example, to proceed with an
                  online booking through the Services, credit or debit card
                  information are collected and processed by third-party
                  payment processors.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 mt-4">
                <h6>The use of this information</h6>
                <p>How we use the information:</p>
                <ul>
                  <li>
                    We provide you with our services, process transactions and
                    send confirmations and invoices;
                  </li>
                  <li>
                    We send you technical notices, updates, security alerts
                    support and administrative messages;
                  </li>
                  <li>
                    We respond to your comments, questions and requests and
                    provide customer service;
                  </li>
                  <li>We manage and improve our products and services;</li>
                  <li>
                    We communicate with you about products, services, offers,
                    promotions, rewards and events offered by Silkhaus and
                    others, and provide information we think will be of
                    interest;
                  </li>
                  <li>We use them for audits and reporting purposes;</li>
                  <li>
                    We detect, investigate and prevent fraudulent transactions
                    and other illegal activities and protect the rights and
                    property of Silkhaus, of our employees, our agents or
                    others, also to comply with applicable law and government
                    requests;
                  </li>
                  <li>
                    We monitor and analyse usage and activities connected to
                    our services;
                  </li>
                  <li>
                    We revise, modify, and update existing Services and
                    develop new services;
                  </li>
                  <li>
                    We use them for any other purpose communicated at the time
                    the information was collected.
                  </li>
                </ul>
                <p>
                  We only collect personal information required to collect by
                  legal obligations or necessary to fulfill the services.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 mt-4">
                <h6>Disclosure of personal information</h6>
                <p>
                  Disclosure of personal information might happen in the
                  following cases and to the following parties:
                </p>

                <ul>
                  <li>
                    <strong>Other companies belonging to or directly affiliated to Silkhaus</strong>, always in accordance with this policy.
                  </li>
                  <li>
                    <strong>
                      Service providers to perform services on our behalf,
                    </strong>
                    like web hosting, email service providers, payment
                    processing, analytics, etc. We might need to share your
                    personal information with them for the above-described
                    purposes. These service providers are located in various
                    countries, so collected personal data may be transferred
                    to and stored in countries outside of your jurisdiction.
                    International transfers of personal information are made
                    following the appropriate safeguards.
                  </li>
                  <li>
                    <strong>Third-parties when required by law,</strong> if
                    necessary to provide, and to protect Our Services.
                  </li>
                  <li>
                    <strong>Third-party partners,</strong> after receiving
                    your explicit consent. Your consent will be requested to
                    share personal information with third parties so that they
                    may provide you with special offers, promotional
                    materials, and other materials that may be of interest to
                    you.
                  </li>
                  <li>
                    <strong>Aggregated information</strong> that cannot
                    identify specific individuals.
                  </li>
                </ul>
                <p>
                  To clarify, Silkhaus may share your Personal Information to
                  parties other than as described above, following your
                  explicit approval, or if we are legally obligated.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 mt-4">
                <h6>Your rights related to your personal information</h6>
                <p>
                  It is important that you have control of your Personal
                  Information. For these reasons we are working to enable you
                  to access, receive a copy of, update, amend, delete, or
                  limit the use of your Personal Information. People located
                  in specific countries, including the European Economic Area,
                  have certain statutory rights in relation to their personal
                  data. Specifically, the following rights:
                </p>

                <ul>
                  <li>
                    Right of access: the right to obtain confirmation of the
                    processing of your personal data, and in this case,
                    information about purposes of processing, the retention
                    period (and the criteria to determine it), to whom your
                    personal data has been or will be disclosed, and a copy of
                    your personal data registered.
                  </li>
                  <li>
                    Right to data portability: the right to receive your
                    personal data provided, in a structured, commonly used and
                    machine-readable format.
                  </li>
                  <li>
                    Right to rectification: the right to obtain rectification
                    of any inaccurate or incomplete personal data about you.
                  </li>
                  <li>
                    Right to erasure (right to be forgotten): the right, in
                    some circumstances, to obtain the cancellation of the
                    personal data stored about you. This is not unreservedly
                    guaranteed, but is limited, especially when colliding with
                    the right of freedom of expression and information. Other
                    exceptions are if the data are necessary to comply with
                    legal obligations, for archiving in the public interest,
                    scientific or historical research purposes or statistical
                    purposes or for the defence of legal claims.
                  </li>
                  <li>
                    Right to restriction of processing: the right, in some
                    circumstances, to obtain restriction of processing to the
                    personal data stores about you.
                  </li>
                  <li>
                    Right to object: the right to object to any processing of
                    your personal data stored. There may be though compelling
                    reasons for us to continue this process, but we will
                    assess your request and respond to you accordingly.
                    Marketing activities are not compelling reasons.{" "}
                  </li>
                </ul>
                <p>
                  Before disclosing records of Personal Information, we may
                  perform additional checks and ask you for additional details
                  to confirm your identity and for security purposes. We
                  reserve the right to charge a fee when allowed by law (e.g.,
                  if your request is unfounded or excessive).
                </p>
                <p>
                  It's your right to file a complaint with your local
                  supervisory authority for data protection (even though we
                  invite you to contact us first).{" "}
                </p>
                <p>
                  In case you wish to exercise one of the above-mentioned
                  rights, please contact us at
                  <a className={classes.link} style={{ color: "#542686" }} href="mailto:info@silkhaus.co">
                    info@silkhaus.co
                  </a>.
                  We will make all reasonable efforts to fulfil your request
                  promptly (unless further information from you are requested
                  to fulfil your request), subject to legal and other
                  permissible considerations.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 mt-4">
                <h6>Information security and privacy incident reporting</h6>
                <p>
                  We take all reasonable operational and technical precautions
                  to protect your personal data against accidental or unlawful
                  destruction, loss, change or damage. Anyhow, we cannot
                  guarantee the absolute security of your personal or other
                  information.
                </p>

                <p>
                  To handle any potential data privacy accident and minimize
                  consequences, we work with a relevant procedure. the
                  occurrence of a data privacy incident (breach) you will be
                  informed as soon as the incident is acknowledged.
                  Additionally, the relevant Data Protection Authority will be
                  informed within 72 hours after the incident has been
                  uncovered.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 mt-4">
                <h6>Interest-based advertising</h6>
                <p>
                  We work with interest-based advertising. This means that
                  when you use our Services, we or third party service
                  providers or advertisers may use cookies (or they collect or
                  we provide to them) or other similar implementations to
                  collect data about your use of the Services (including
                  mobile applications) or your online activities over time and
                  across various sites and devices. Captured information may
                  include: contents you view, date and time of the view, the
                  website that referred you to the Services. This information
                  may be associated with your unique browser, device
                  identifier, or Internet Protocol (IP) address. These help
                  tailor advertisements to be relevant and useful to you. They
                  may appear on the Services or on other websites,
                  applications or properties.
                </p>

                <p>
                  Moreover, if you have provided your email address to us, we
                  may use it to deliver tailored advertisements to you on the
                  Services or on other websites. We do not access or collect
                  any of the contents of your email messages in providing
                  interest-based advertising. Our use of information received
                  from Gmail APIs will adhere to Google's Limited Use
                  Requirements.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 mt-4">
                <h6>Communication from Silkhaus</h6>
                <p>
                  We may use your Personal Information to send you promotional
                  content and messages by email, text messages, direct text
                  messages, marketing calls and similar forms of
                  communication. If you do not wish to receive such
                  promotional messages or calls, you may opt-out communicating
                  to us at any time or following the "unsubscribe" link. We
                  work to limit the promotional content we send you to a
                  reasonable and proportionate level, and to send you
                  information we think may be of interest or relevance to you,
                  based on your information.
                </p>

                <p>
                  We may contact you with important information regarding our
                  Services, or your use of it: billing information; replies to
                  your support ticket or email; reminders or warnings
                  regarding upcoming or late payments; notify you of changes
                  in our Services. It is important that you are always able to
                  receive such messages. This is why, you will not be able to
                  opt-out of receiving such Service and Billing Messages
                  unless you are no longer a Silkhaus website User or property
                  Guest.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 mt-4">
                <h6>Third parties information collection opt out</h6>
                <p>
                  Silkhaus's third-party ad servers, ad network providers,
                  third-party advertisers may provide you with advertisements
                  that you may see on the Services or on other affiliated
                  websites. To improve the relevancy and help measure the
                  effectiveness of such advertisements, the Silkhaus Ad
                  Providers may use cookies, web beacons, clear gifs or
                  similar technologies. These are used to record users'
                  activity, such as the pages visited, and to learn what types
                  of information are of most interest to the users.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 mt-4">
                <h6>Third-party links and websites</h6>
                <p>
                  We may link to the websites of other companies and/or
                  individuals. These third-party websites may collect
                  information about users on those websites, and Silkhaus'
                  privacy policy does not apply to these. Please refer
                  directly to these third parties and websites about their
                  privacy policies.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 mt-4">
                <h6>Children's privacy</h6>
                <p>
                  Our Services are meant for individuals who are 18 years old
                  or above, unless we specify otherwise. For under 18, we do
                  not knowingly collect personal data, without prior parental
                  consent. If you realize that your child has provided us with
                  any personal data, without your prior consent, please inform
                  us, so we can delete the information and close any account
                  that your child may have created with us.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 mt-4">
                <h6>Updates to this policy</h6>
                <p>
                  This Silkhaus Privacy Policy may be revised or updated
                  occasionally to adhere to legal, technological or
                  operational needs. We invite you to visit occasionally this
                  page to stay updated. Critical changes will be notified to
                  you prior to implementation. Changes will be effective from
                  the publishing date
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 mt-4">
                <h6>Contact us</h6>
                <p>
                  In case of questions about this Privacy Policy or to
                  exercise any of your rights, you can contact us at
                  <a className={classes.link} style={{ color: "#542686 " }} href="mailto:info@silkhaus.co">
                    info@silkhaus.co
                  </a>.
                  We do our best to resolve any complaints about use of
                  Personal Information in accordance with this Privacy Policy.
                </p>
              </div>
            </div>
            
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

const useStyles = createUseStyles({
  main: {
    width: "100%",
    display: "flex",
    justifyContent: "space-around",
    // backgroundColor: "red",
    // height: 400,
    paddingTop: 50,
    paddingBottom: 30,
  },
  container: {
    width: "100%",
    padding: "0px 10px 0 10px",
    // backgroundColor: "green",
    display: "flex",
    flexDirection: "column",
    // justifyContent: "space-between",

    "@media (min-width:900px)": {
      padding: "0px 50px 0 50px",
    },

    "@media (min-width:1536px)": {
      maxWidth: "1536px",
      padding: "0px 0 0 0",
    },
  },

  wrapper: {
    display: "flex",
    flexDirection: "column",

    justifyContent: "space-between",
    marginTop: 40,
    paddingBottom: 40,

    // backgroundColor: "pink",

    "@media (min-width:950px)": {
      flexDirection: "row",
    },
  },
  link: {
    marginLeft: 2,
    color: "#542686",
  },
  textBold: {
    fontWeight: 700,
  },
});

export default PrivacyPolicy;
